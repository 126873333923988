import api from '@/utils/api'

export const state = {
	userInfo: null
}

export const getters = {
	userInfo: state => state.userInfo
}

export const mutations = {
	UPDATE_USER_INFO(state, payload) {
		state.userInfo = payload
	}
}

export const actions = {
	refreshUserInfo({ commit }) {
		api.get('charity-user/user-info')
			.then((response) => {
				if (response.status === 200) {
						commit('UPDATE_USER_INFO', response.data.data)
					}
				})
			.catch(() => {
				commit('UPDATE_USER_INFO', null)
			})
	}
}
