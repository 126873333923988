import api from '@/utils/api'

export default [
	{
		path: '/',
		meta: { authRequired: true },
		name: 'home',
		component: () => import('./views/home')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('./views/account/login'),
		meta: {
			async beforeResolve(to, from, next) {
				await api.get('charity-user/user-info')
					.then((response) => {
						if (response.status === 200) {
							let userInfo = response.data.data
							if (userInfo.admin_level === 1) {
								window.location = process.env.VUE_APP_REACT_ADMIN_DASHBOARD_URL
							} else {
								window.location = process.env.VUE_APP_IG2V2_CHARITY_DASHBOARD_URL
							}
						} else {
							next()
						}
					})
					.catch(() => {
						next()
					})
			},
		},
	},
	{
		path: '/request-reset',
		name: 'RequestReset',
		component: () => import('./views/account/request-reset'),
		meta: {
			authRequired: false,
		}
	},
	{
		path: '/reset-password/:resetKey',
		name: 'ResetPassword',
		component: () => import('./views/account/reset-via-email'),
		props: true,
		meta: {
			authRequired: false,
		}
	},
	{
		path: '*',
		name: 'Page-404',
		component: () => import('./views/pages/error-404')
	}
]