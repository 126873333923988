import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss";
import Sparkline from 'vue-sparklines';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

Sentry.init({
	dsn: process.env.VUE_APP_SENTRY_DSN,
	integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
	release: process.env.VUE_APP_VERSION,
	environment: process.env.NODE_ENV
});

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.component('apexchart', VueApexCharts)

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
